:root {
  // Primary: 
  --primary-color: #0a8fe7;
  --light-primary-color: #169af1;
  // Secondary: 
  --secondary-color: #fd772a;
  --light-secondary-color: #ff6f1b;
  // Green: 
  --green: #01af3b;
  --green-1: rgb(3, 202, 69);
  // White:   
  --white: #FFFFFF;
  --white-1: #F0F6FF;
  --white-2: #d0daeb;
  --white-3: #e5ecf3;
  --white-4: #f6f8fa;
  // Black: 
  --black: #000;
  --black-1: #131212;
  // Grey: 
  --grey: #e6e6e6;
  --grey-1: #fcfcfc;
  --grey-2: #cfcfcf;
  --grey-3: #a7a7a7;
  // purple: 
  --purple: #120F1D;
  --purple-1: #1E1B30;
  --red: #f14747;
  }