.confirmationContainer {
    display: flex;
    flex-flow: column;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    color: var(--black-1);
    margin: 0 auto;
    text-align: center;
    .logo {
        width: 150px;
        height: 100px;
        margin-bottom: 30px;
    }
}