.submitButton {
    width: 100%;
    height: 100%;    
    .button {
        color: white;
        font-size: 1.4rem;
        cursor: pointer;
        width: 100%;
        height: 100%;
    }
}

.green {
    .button {
        background-color: var(--green-1);
        &:hover {
        background-color: var(--green);
        }
    }
}

.purple {
    .button {
        background-color: var(--purple);
        &:hover {
        background-color: var(--purple-1);
        }
    }
}