@import "../../assets/scss/sizes.scss";
.resetPageContainer {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
        .formContainer {
            width: 400px;
        }
        .invalidToken {
            font-size: 2.2rem;
            font-weight: 500;
        }
}


@media (max-width: $medium-devices){
    .resetPageContainer {
            .formContainer {
                width: 90vw;
            }
    }
}