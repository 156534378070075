.resetContainer {
    width: 100%;
}
.resetForm {
    margin-top: 20px;
    .title {
        margin-bottom: 20px;
        font-size: 3rem;
    }
    .inputWrapper {
        width: 100%;
        height: 70px;
        margin-bottom: 20px;
        .helpers {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            margin-top: 5px;
            .error {
                font-size: 1.4rem;
                margin-inline-start: 5px;
                color: var(--red);
            }
            .reset {
                cursor: pointer;
                color: var(--black-1);
                margin-inline-end: 5px;
                transition: ease-in-out 0.2s color;
                &:hover {
                color: var(--light-primary-color)
                }
            }
        }
    }
    .buttonWrapper {
        width: 100%;
        height: 40px;
        font-size: 2rem;
    }
}

.invalidToken {
    text-align: center;
    font-size: 2.2rem;
    font-weight: 500;
}