@import "../../../assets/scss/sizes.scss";
.loginContainer {
    width: 100%;
    .forbidden {
        text-align: center;
    }
}
.loginForm {
    margin-top: 20px;
    .signin {
        font-size: 2rem;
        text-align: left;
        margin-bottom: 5px;
    }
    .note {
        font-size: 1.4rem;
        text-align: left;
        margin-bottom: 20px;
    }

    .inputWrapper {
        width: 100%;
        height: 70px;
        margin-bottom: 20px;
        .helpers {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            margin-top: 5px;
            .error {
                font-size: 1.4rem;
                margin-inline-start: 5px;
                color: var(--red);
            }
        }
    }
    .buttonWrapper {
        width: 100%;
        height: 40px;
        font-size: 2rem;
    }

}
.providers {
    margin-top: 10px;
    display: flex;
    flex-flow: row;
    justify-content: space-around;
}

@media (max-width: $extra-small-devices){
    .providers {
        margin-top: 10px;
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        .googleLogin {
            margin-bottom: 10px;
            button {
                width: 100%;
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
    