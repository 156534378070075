@import "./colors.scss";
@import "./sizes.scss";
html {
    font-size: 10px;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-family:  Tajawal;
    overflow-x: hidden;
  }

  body {
    font-size: 1.6rem;
    color: var(--black-1);
    background-color: var(--white-1);
  }
  input, button {
    font-family:  Tajawal;
  }
  input[type=text] {   
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  *,
  *:before,
  *:after {
    margin: 0;
    box-sizing: border-box;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

// For material UI select components: 
.MUIformControl  {
    width: 100% !important;
    height: 100%;
}
.MUIselectInput {
    background-color: transparent !important;
}
.MUIInputLabel {
  font-size: 1.6rem !important;
}
